<script>
export default {
	hookZone: ['end'],
}
</script>

<template>
	<div class="grey lighten-3 mb-4 pa-2 d-flex align-start rounded-lg font-0">
		<v-icon color="primary" small left>mdi-alert-circle</v-icon>
		<div>
			Informamos que la persona designada para recibir el pedido, debe tener de manera física la tarjeta
			con la que se realizó la compra, y el DNI del titular de la misma. En el caso de los retiros por
			Sucursales, solo puede retirar la compra el titular de la tarjeta y presentar la misma y el DNI de
			manera física.
		</div>
	</div>
</template>

